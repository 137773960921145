//Bootstrap 3.3.4
// Core variables and mixins
@import "../vendor/bootstrap/variables.less";
@import "local-variables.less";
@import "../vendor/bootstrap/mixins.less";
@import "local-mixins.less";

// Reset and dependencies
@import "../vendor/bootstrap/normalize.less";
@import "../vendor/bootstrap/print.less";
@import "../vendor/bootstrap/glyphicons.less";

// Core CSS
@import "../vendor/bootstrap/scaffolding.less";
@import "../vendor/bootstrap/type.less";
@import "../vendor/bootstrap/code.less";
@import "../vendor/bootstrap/grid.less";
@import "../vendor/bootstrap/tables.less";
@import "../vendor/bootstrap/forms.less";
@import "../vendor/bootstrap/buttons.less";

// Components
@import "../vendor/bootstrap/component-animations.less";
@import "../vendor/bootstrap/dropdowns.less";
@import "../vendor/bootstrap/button-groups.less";
@import "../vendor/bootstrap/input-groups.less";
@import "../vendor/bootstrap/navs.less";
@import "../vendor/bootstrap/navbar.less";
@import "../vendor/bootstrap/breadcrumbs.less";
@import "../vendor/bootstrap/pagination.less";
@import "../vendor/bootstrap/pager.less";
@import "../vendor/bootstrap/labels.less";
@import "../vendor/bootstrap/badges.less";
@import "../vendor/bootstrap/jumbotron.less";
@import "../vendor/bootstrap/thumbnails.less";
@import "../vendor/bootstrap/alerts.less";
@import "../vendor/bootstrap/progress-bars.less";
@import "../vendor/bootstrap/media.less";
@import "../vendor/bootstrap/list-group.less";
@import "../vendor/bootstrap/panels.less";
@import "../vendor/bootstrap/responsive-embed.less";
@import "../vendor/bootstrap/wells.less";
@import "../vendor/bootstrap/close.less";

// Components w/ JavaScript
@import "../vendor/bootstrap/modals.less";
@import "../vendor/bootstrap/tooltip.less";
@import "../vendor/bootstrap/popovers.less";
@import "../vendor/bootstrap/carousel.less";

// Utility classes
@import "../vendor/bootstrap/utilities.less";
@import "../vendor/bootstrap/responsive-utilities.less";

@import url("https://use.typekit.net/ulq4jvg.css");

#breakpoint
{
	background-color: #ff00ff;
	color: #fff;
	font-weight: bold;
	padding: 8px;
	position:fixed;
	z-index: 9999999;
}

.animate {-webkit-transition: 0.3s;
	-moz-transition: 0.3s;
	-o-transition: 0.3s;
	transition: 0.3s;}

a {
	color: @lenovo-red;
	text-decoration: none;
}
a:hover {
	color: @lenovo-red;
	text-decoration: underline;
}
a,
a:focus,
a:active,
button
{
  outline: none !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.center
{
	text-align: center;
}

.v-center
{
	top: 50%;
	transform: translateY(-50%);
}

.left
{
	float: left;
}

.right
{
	float: right !important;
}

.light
{
	color: #CCC;
}

.btn-xl
{
	padding: @padding-xl-vertical @padding-xl-horizontal !important;
	font-size: @font-size-xl !important;
	line-height:  @line-height-large !important;
	border-radius: @border-radius-large !important;
}



/* Bootstrap Overrides */
.sidebar-nav
{
  padding: 9px 0;
}


input, textarea, select, .uneditable-input
{
  margin-bottom: 0;
}

*
{
  -webkit-border-radius: 0 0 0 0 !important;
  -moz-border-radius: 0 0 0 0 !important;
  border-radius: 0 0 0 0 !important;
}

.popover_content
{
  display: none;
}

.modal-footer .throbber
{
  margin: 4px 10px;
  vertical-align: middle;
}

input, textarea, select, .uneditable-input
{
  color: inherit;
}

.breadcrumb
{
	background-color: #ffffff;
	border-top: 1px solid #707070;
	border-bottom: 1px solid #707070;
	font-size: 12px;
	margin-top: 4px;
}




.navbar-default
{
	background: #ffffff;
	height: 57px;
	border: none;
}

a.navbar-brand
{
	display: block;
	width: 50px;
	height: 150px;
	margin: 0 0 0 -15px;
	position: absolute;
	text-indent: -9999px;
	background: @lenovo-red url("/img/logo-clear.png") no-repeat;
	background-size: contain;

	&:hover
	{
		background-color: @lenovo-red !important;
	}
}

.navbar-inner
{
	background: #ffffff;
}

.scrolled.navbar-brand
{
	height: 56px;
	position: absolute;
	top: 0px;
	background: @lenovo-red url("/img/logo-sm.png") no-repeat top center;
	background-size: cover;
}

.navbar-collapse
{
	background-color: #FFFFFF;
}

@media (max-width: @screen-xs-max)
{
	a.navbar-brand,
	.scrolled.navbar-brand
	{
		display: block;
		width: 73px;
		height: 56px;
		margin: 0 !important;
		position: relative;
		text-indent: -9999px;
		background: @lenovo-red url("/img/logo-sm.png") no-repeat top center;
		background-size: cover;
	}

	.navbar-inner,
	.scrolled.navbar-inner
	{
		margin-top: 0;
	}
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max)
{
	a.navbar-brand,
	.scrolled.navbar-brand
	{
		display: block;
		width: 204px;
		height: 56px;
		margin: 0;
		position: relative;
		text-indent: -9999px;
		background: @lenovo-red url("/img/logo-clear-horizontal.png") no-repeat center center;
		background-size: cover;
	}

	.navbar-inner,
	.scrolled.navbar-inner
	{
		margin-top: 0;
	}
}

@media (max-width: @screen-sm-max)
{
	.navbar
	{
		border-bottom: 1px solid #ffffff;
	}
}

.navbar-header
{
	background-color: #FFFFFF;
	float: none !important;
}

.navbar-nav
{
	li
	{
		a
		{
			color: @lenovo-dark-gray !important;
			padding-top: 10px;

			&:hover
			{
				color: @lenovo-red !important;
			}
		}

		&.active
		{
			a
			{
				background: transparent !important;
				color: @lenovo-green !important;
			}
		}
	}
}

.navbar-right
{
	margin-right: 0;
}

.navbar-toggle
{
	margin-top: 12px;
}

@media (max-width: @screen-xs-max)
{
	#usermenu
	{
		display: none;
	}
}

@media (min-width: @screen-sm-min)
{
	#navbar
	{
		display: none !important;
	}

	#usermenu
	{
		display: block;
	}
}

@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max)
{
	.navbar-right
	{
		.btn-label
		{
			display: none;
		}
	}

	.navbar-collapse
	{
		border-bottom: 1px solid #E7e7e7 !important;
	}
}

@media (max-width: @screen-sm-max)
{
	.navbar-header
	{
		h2
		{
			display: none;
		}
	}

	#countryselect
	{
		margin-left: 50px !important;
	}

	.navbar-header
	{
		/*.navbar-header h2
		{
		  margin: 13px 15px 0 0;

		  #tagline_brand
		  {
			display: none;
		  }
		}*/

		.navbar-brand
		{
			margin-left: -15px;
		}
	}

	.navbar-collapse
	{
		border: none;

		.navbar-nav
		{
			margin-top: 5px !important;
		}
	}

	.navbar-collapse.in
	{
		.navbar-right
		{
			//border-bottom: 1px solid #E7e7e7;
		}
	}

	.navbar-nav
	{
		margin: 0 !important;
	}
}

@media (min-width: @screen-md-min)
{
	.navbar-collapse
	{
		padding-left: 97px !important;
	}
}


@media (min-width: @screen-md-min)
{
	#contentwrap
	{
		padding-left: 73px;
	}
}


#countryselect
{
	float: left;
	text-align: left;
	overflow: hidden;
	margin: 12px 0 0 73px;
	z-index: 999999;
}

#countryselect p {margin: 0;text-transform: uppercase;font-weight: bold;font-size: 10px;}

#usermenu
{
	float: right;
	margin-top: 18px;

	#usermenu_label
	{
		display: block;
		color: @lsc-gray;
		font-size: 16px;
		background: url(/img/icon_user.png) left center no-repeat;
		background-size: contain;
		padding-left: 30px;
	}
}

.clear
{
	clear: both;
	display: block;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	width: 0;
}


#hero
{
	background: url(/img/bg_hero.jpg) no-repeat;
	background-size: cover;
	background-position: center center;
	margin-bottom: 52px;
	color: #ffffff;

	&.muted
	{
		background: linear-gradient(
				rgba(53, 63, 72, 0.85),
				rgba(53, 63, 72, 0.85)
		),
		url(/img/bg_hero.jpg) no-repeat;
		background-size: cover;
		background-position: center center;
	}

	.copy
	{
		margin-top: 72px;

		h2
		{
			margin-bottom: 1em;
		}

		p
		{
			font-size: 16px;
			margin-bottom: 1em;
		}
	}

	.breadcrumb
	{
		background: transparent;
		padding-left: 0;
		position: absolute;
		border: none;
		margin-top: -72px;

		li
		{
			font-size: 12px;

			&.active
			{
				color: @lenovo-red;
			}

			a
			{
				color: #ffffff;
			}
		}
	}

	.callout
	{
		margin: 0 0 50px 0;

		.description
		{
			padding: 40px 30px;
		}
	}

	#search_trigger
	{
		border: none;
		background: rgba(51, 63, 72, .85);
		padding: 12px 40px;
		color: #ffffff;
		font-size: 21px;
		font-weight: 300;
		float: right;

		@media (min-width: @screen-md-min)
		{
			margin-right: 25px;
		}
	}

	#search_ui
	{
		background: rgba(51, 63, 72, .85);
		padding: 12px 32px;
		color: #ffffff;

		@media (min-width: @screen-md-min)
		{
			float: right;
			margin-right: 25px;
		}

		h4
		{
			font-size: 21px;
			font-weight: 300;
			border-bottom: 1px solid #ffffff;
			padding-bottom: 12px;
		}

		form
		{
			padding: 0;
		}

		label
		{
			font-size: 16px;
			font-weight: 300;
			color: #ffffff;
		}

		.form-control
		{
			margin: 8px 0;
		}

		.help-inline
		{
			font-size: 16px;
			font-weight: 300;
			color: #ffffff;
		}
	}
}

@media (min-width: @screen-md-min)
{
	#hero
	{
		margin-left: -88px;
		padding-left: 75px;
	}
}

@media only screen and (min-width: 320px) {

	/* Small screen, non-retina */

}

@media
only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 320px),
only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 320px),
only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 320px),
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 320px),
only screen and (                min-resolution: 192dpi) and (min-width: 320px),
only screen and (                min-resolution: 2dppx)  and (min-width: 320px) {

	/* Small screen, retina, stuff to override above media query */
}

@media only screen and (min-width: 700px) {

	/* Medium screen, non-retina */

}

@media
only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 700px),
only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 700px),
only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 700px),
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 700px),
only screen and (                min-resolution: 192dpi) and (min-width: 700px),
only screen and (                min-resolution: 2dppx)  and (min-width: 700px) {

	/* Medium screen, retina, stuff to override above media query */
	#hero
	{
		background-image: url(/img/bg_hero@2x.jpg);

		&.muted
		{
			background: linear-gradient(
					rgba(53, 63, 72, 0.85),
					rgba(53, 63, 72, 0.85)
			),
			url(/img/bg_hero@2x.jpg) no-repeat;
			background-size: cover;
			background-position: center center;
		}
	}

}

@media only screen and (min-width: 1300px) {

	/* Large screen, non-retina */

}

@media
only screen and (-webkit-min-device-pixel-ratio: 2)      and (min-width: 1300px),
only screen and (   min--moz-device-pixel-ratio: 2)      and (min-width: 1300px),
only screen and (     -o-min-device-pixel-ratio: 2/1)    and (min-width: 1300px),
only screen and (        min-device-pixel-ratio: 2)      and (min-width: 1300px),
only screen and (                min-resolution: 192dpi) and (min-width: 1300px),
only screen and (                min-resolution: 2dppx)  and (min-width: 1300px) {

	/* Large screen, retina, stuff to override above media query */
	#hero
	{
		background-image: url(/img/bg_hero@2x.jpg);

		&.muted
		{
			background: linear-gradient(
				rgba(53, 63, 72, 0.85),
				rgba(53, 63, 72, 0.85)
			),
			url(/img/bg_hero@2x.jpg) no-repeat;
			background-size: cover;
			background-position: center center;
		}
	}
}


@media (max-width: @screen-sm-max)
{
	#hero
	{
		padding-left: 0;
	}
}

@media (min-width: @screen-lg-min)
{
	#hero
	{
		background-image: url(/img/bg_hero@2x.jpg);

		&.muted
		{
			background: linear-gradient(
					rgba(53, 63, 72, 0.85),
					rgba(53, 63, 72, 0.85)
			),
			url(/img/bg_hero@2x.jpg) no-repeat;
			background-size: cover;
			background-position: center center;
		}
	}
}

form
{
  padding-bottom: 20px;
}

#content
{
	margin-top: 57px;
	padding-bottom: 200px;
}

#copy, #fullcopy
{
}

#col-intro
{
	float: left;
	overflow: hidden;
	padding: 10px 30px;
	width: 280px;
}

#col-select
{
	margin: 40px 25px 40px 25px;
	padding: 10px 25px 25px 25px;
	background: @lsc-gray;

	select
	{
		option
		{
			font-size: 18px;
		}
	}

	form
	{
		margin: 0;
		padding: 0;
	}

	label
	{
		color: #ffffff;
		font-size: 21px;
		font-weight: 300;
		margin-bottom: 10px;
	}

	button
	{
		padding: 10px 32px;
	}

	.help-block
	{
		color: #ffffff;
	}
}

#singlecol, .singlecol
{
	clear: both;
	overflow: hidden;
	padding: 10px 20px 32px 20px;
	width: 869px
}

.leftcol
{
	float: left;
	overflow: hidden;
	padding: 10px 10px 10px 20px;
	width: 424px
}
.rightcol
{
	clear: right;
	float: left;
	overflow: hidden;
	padding: 10px 20px 10px 10px;
	width: 425px
}

#footerwrap
{
	background: @lsc-gray;
	color: #ffffff;
	bottom: 0;
	width: 100%;
	position: fixed;

	ul
	{
		list-style: none;
		margin: 10px 0 20px 0;
		padding: 0;
		overflow: hidden;

		@media (min-width: @screen-sm-min)
		{
			margin: 10px 0 120px 0;
		}

		li
		{
			margin: 0;
			padding: 0;

			@media (min-width: @screen-sm-min)
			{
				float: left;
				margin-right: 20px;
			}

			a, a:visited
			{
				color: #ffffff;
				font-size: 12px;
			}

			a:hover, a:active
			{
				color: @lenovo-light-blue;
			}
		}
	}

	#copyright
	{
		font-size: 10px;
		margin-bottom: 10px;
	}
}

.callout
{
	margin-top: 32px;
	margin-bottom: 40px;

	&:hover
	{
		.description
		{
			background-color: @lenovo-orange;
		}


		.dropdown-wrap
		{
			ul
			{
				display: block;
			}
		}
	}

	.dropdown-wrap
	{
		position: relative;

		ul
		{
			display: none;
			position: absolute;
			list-style: none;
			margin: 0;
			padding: 0;
			width: 100%;
			z-index: 999999;

			li
			{
				margin: 0;
				padding: 0;

				a
				{
					display: block;
					font-size: 12px;
					line-height: 1.5em;
					padding: 3px 16px !important;
					white-space: normal !important;
					color: @lsc-dark-gray;
					background-color: #ffffff;
				}
			}

			li > a:hover,
			.active > a,
			.active > a:hover
			{
				color: #ffffff;
				background-color: @lsc-dark-gray;
				text-decoration: none;
			}
		}
	}

	> a
	{
		&:hover,
		&:active
		{
			text-decoration: none;
		}

		display: block;
		text-align: center;
		color: #ffffff;
		font-size: 18px;

		&.description
		{
			background-color: @lenovo-light-blue;
			padding: 20px 30px;
		}

		&.month
		{
			background-color: @lsc-dark-gray;
			text-transform: uppercase;
			padding: 6px 0;
		}
	}
}

/* Grid */
#gridkey
{
	float: right;
	overflow: hidden;

	.keyitem
	{
		float: left;
		margin-left: 40px;
	}

	.swatch
	{
		float: left;
		height: 15px;
		margin: 4px 8px 0 0;
		text-indent: -9999px;
		width: 15px;
	}

	.keylabel
	{
		float: left;
		overflow: hidden;
	}

	#newmodel.swatch {background-color: #ffffa3;}
	#focus.swatch {background-color: #a3ffc2}
	#withdrawn.swatch {background-color: #ffc2c2;}
}

#grid
{
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif !important;

  tbody
  {
	tr.k-state-selected td
	{
		background: inherit;
	}

	td
	{
	  background-color: #fff;
	}
  }
}

#utility
{
	border-bottom: 1px solid #707070;
	border-top: 1px solid #707070;
	clear: both;
	margin: 0 0 20px 0;
	overflow: hidden;
	padding: 5px 0;
}

#utility select
{
}

#utility ul
{
	display: block;
	float: right;
	list-style: none;
	margin: 0;
	overflow: hidden;
}

#utility ul li
{
	border-right: 1px solid #000;
	color: #222;
	float: left;
	margin-top: 3px;
}

#utility ul li.last
{
	border-right: none;
}

#utility ul li.last a
{
	margin-right: 0;
}

#utility ul li a
{
	color: #000;
	font-size: 10px;
	font-weight: bold;
	margin: 0 10px 0 10px;
	text-decoration: none;	
	text-transform: uppercase;
}

#utility ul li a:hover
{
	color: #ec2f34;
}

a.configtrigger
{
	background-color: #959595;
	border: 1px solid #fff;
	color: #fff;
	display: block;
	margin: 0;
	padding: 4px 10px;
	text-decoration: none;
	white-space: nowrap;
}

.systemname.newmodel {background-color: #ffffa3;}
.systemname.focus {background-color: #a3ffc2}
.systemname.withdrawn {background-color: #ffc2c2;}

/* Configrations */
.configrow h3 {margin: 0;}
.configrow h4 {margin: 0;}

a#member__config_options,
a#member_config_services,
a#member__config_thidparty,
a#anon_config_options,
a#anon_config_services,
a#anon_config_thidparty
{
	font-weight: bold;
	text-decoration: none;
}

div.categoryLabel {overflow: hidden;}

div.categoryLabel ul
{
	float: left;
	list-style: none;
	margin: 0;	
	padding: 0;

}

div.categoryLabel li
{
	border-right: 1px solid #595a5c;
	color: #595a5c;
	float: left;
	font-size: 16px;
	font-weight: 300;
	margin: 0 6px 0 0;
	padding:  0 6px 0 0;
}

div.categoryLabel li.last {border-right: none;color: #231f20;font-weight: bold;}

#toggle_compatibility
{
	color: #0e5da1 !important;
	display: block;
	font-weight: bold;
	text-decoration: none;
}

/* Recent Configurations */
#recentconfigs-wrap
{
  background-color: #5c5c5c;
  padding-right: 0;

  #recentconfigs
  {
	background-color: #5c5c5c;
	color: #ffffff;
	//float: right;
	//margin-left: 10px;
	min-height: 300px;
	overflow: hidden;
	padding: 0 20px 20px 0;
	//width: 210px;
  }

  #recentconfigs input {
	color: #333;
  }

  #recentconfigs h3 {
	color: #ffffff;
	display: block;
	border-bottom: 1px dotted #fff;
	font-size: 16px;
	padding-bottom: 8px;
  }

  #recentconfigs label, #recentconfigs a {
	color: #ffffff;
  }
}
.configlink {font-weight: bold;text-decoration: none;}

.configrow
{
	color: #fff;
	font-size: 11px;
	margin-bottom: 10px;
	overflow: hidden;
}
.configrow .thumb {float: left;overflow: hidden;margin-right: 10px;width: 70px;}
.configrow .thumb img {width: 70px;}
.configrow .details {float: left;overflow: hidden;width: 130px;}
.configrow .details a
{
	color: #fff;
	font-size: 11px;
	font-weight: bold;
	text-decoration: none;
}

/* Comparisons */
table#compare tbody td
{
	color: #000;
}

table.topseller th
{
	background: #EC2F34 !important;
	color: #fff !important;
	font-size: 12px;
	font-weight: normal;
	white-space: nowrap;
}

table.topseller td
{
	font-size: 12px;
}

/* Forms */
/*
input[type=text], input[type=password], input.text, input.title, textarea
{
	border: 1px solid #666;
	padding: 4px;
	margin: 0 0 15px 2px;
}
*/

.formrow {overflow:hidden;margin-bottom: 10px;vertical-align: top;}
.formrow  label {width: 140px;float: left;vertical-align: top;}
.formrow  .membershipchoices label {width: 400px;}
label{display:block;font-weight:bold;color:#424242;margin:5px 0 0 0;}

.req
{
	color: #ef3e42;
}

.throbber
{
	background: url('/images/throbber.svg');
	display: inline-block;
	height: 16px;
	width: 16px;
}

/*  Pager  */
.pagerpro {
  float: right;
  list-style: none;
  margin: 0;
  padding: 0; }

.pagerpro li {
  display: inline;
  float: left; }

.pagerpro a {
  display: block;
  padding: 3px;
  padding-bottom: 2px;
  text-decoration: none;}

.pagerpro a:hover {
  background: #ec2f34;
  border-color: #ec2f34;
  border-bottom: 1px solid #ec2f34;
  color: white;
  text-decoration: none; }

.pagerpro .current a,
.pagerpro .current a:hover {
  background: transparent;
  border-color: #ec2f34;
  border-bottom: 2px solid #ec2f34;
  color: #ec2f34;
  font-weight: bold;
  padding-left: 2px;
  padding-right: 2px; }

/* Configurator */
#systeminfo
{
  margin-bottom: 20px;
  overflow: hidden;
  padding-top: 23px;
  padding-bottom: 10px;

  #systemType {
	float: left;
	font-size: 21px;
	font-weight: 300;
  }

/*
#viewConfig
{
	background-color: #ec2f34;
  border: none;
  clear: right;
  color: #FFFFFF;
  display: block;
  float: right;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  padding: 4px 13px;
  width: 100px;
  margin: 0 0 10px 0;
}
#editConfig, #editOptionsConfig, #editServicesConfig, #editThirdPartyOptionsConfig {
  background-color: #ec2f34;
  border: none;
  clear: both;
  color: #FFFFFF;
  display: block;
  float: right;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  margin-top: 15px;
  padding: 4px 13px;
  width: 100px;
  margin: 0 0 10px 0;
}
*/

  a#changesystemtype
  {
	clear: both;
	color: #595a5c;
	display: block;
	font-size: 9px;
	line-height: 1em;
	margin-top: 10px;
	text-decoration: none;
	text-transform: uppercase;
  }

  img#thumb
  {
	float: left;margin-right: 10px;
  }

  #instructions
  {
	float:right;margin: 0;
  }
}

#actions {float: right;}
#actions a {margin-left: 4px;}

#catTree
{
  font-size: 14px;
  font-weight: bold;
  float: left;
  margin-bottom: 40px;
  padding-left: 20px;
  //width: 302px;
}

#catTree h4
{
  color: #414142;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5em;
}

#catTree ul
{
  list-style: none;
  margin: 0;
  padding: 0;
}

#catTree ul li
{
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;

}

#catTree a
{
  color: #414142;
  font-size: 14px;
  font-weight: bold;
  text-decoration: none;
}

#allAccessoriesTrigger,
#allServicesTrigger
{
  background-color: #a7a7a7;
  color: #fff !important;
  display: block;
  float: left;
  margin: 5px 0;
  padding: 5px 0;
  text-align: center;
  width: 50%;
}

#allAccessoriesTrigger
{
  border-right: 1px solid #fff;
}

#allAccessoriesTrigger.selected,
#allServicesTrigger.selected
{
  background-color: #dc291e;
}

#catTree ul li.active > a
{
  color: #d31245;
}

#catTree ul li.active ul li a
{
}

#catTree li.selected
{
  background-color: #dc291e;
}

#catTree li.selected.active > a
{
  color: #fff !important;
}

#catTree ul li ul
{
  /*	padding-left: 1.5em;*/
}

#catTree ul li ul li
{
  border-top: none;
  padding: 0;
}

#catTree ul li ul li a
{
  color: #010101;
  font-size: 12px;
  padding-left: 21px;
}

#catTree ul li ul li a.active
{
  background-color: #d1d3d5;
  color: #010101;
}

#catTree ul li ul li ul
{
}

#catTree ul li ul li ul li
{
  padding: 0;
}

#catTree ul li ul li ul li a
{
  font-weight: normal;
  padding-left: 42px;
}

div.accessory {overflow:hidden;clear:both;}

#slider {width: 1260px;margin-top: 40px;}
#slider #list-container {float: left;width: 600px;}
#slider #detail-container {float: right;width: 600px;}

#slider td {border-top: none;}

div.categoryLabel {overflow: hidden;}

div.categoryLabel ul
{
  float: left;
  list-style: none;
  margin: 0;
  padding: 0;

}

div.categoryLabel li
{
  border-right: 1px solid #595a5c;
  color: #595a5c;
  float: left;
  font-size: 16px;
  font-weight: 300;
  margin: 0 6px 0 0;
  padding:  0 6px 0 0;
}

div.categoryLabel li.last {border-right: none;color: #231f20;font-weight: bold;}


/* Override Bootstrap */
.table > thead > tr > td.active, .table > tbody > tr > td.active, .table > tfoot > tr > td.active, .table > thead > tr > th.active, .table > tbody > tr > th.active, .table > tfoot > tr > th.active, .table > thead > tr.active > td, .table > tbody > tr.active > td, .table > tfoot > tr.active > td, .table > thead > tr.active > th, .table > tbody > tr.active > th, .table > tfoot > tr.active > th {
  background-color: inherit;
}

div.category td {vertical-align: top;}
div.category thead td, div.recommended thead td {font-weight: bold;}
div.category tr.active, div.recommended tr.active {background-color: #bdfdb3 !important;}
div.category tr.recommended {background-color: #d5edf8;}
div.category table.accessories td.thumb, div.recommended td.thumb {width: 132px;}
div.category table.accessories td.name, div.recommended td.name {width: 220px;}
div.category td.name, div.recommended td.name {width: 406px;padding-right: 40px;}
div.category td.name a, div.recommended td.name a {color:#222;text-decoration: none;}
div.category td.part, div.recommended td.part {width: 50px;}
div.category td.price, div.recommended td.price {width: 60px;text-align: right;white-space: nowrap;}
/*div.category td.controls {width: 130px;}*/
.controls a, a.add
{
  background: #bcbebf;
  color: #000;
  font-size: 9px;
  font-weight: bold;
  padding: 3px 12px;
  text-decoration: none;
  text-transform: uppercase;
  white-space: nowrap;
}

a.add, a.add_link
{
  background-color: #7FC479 !important;
  color: #ffffff !important;
}

/* to revert to old links, remove trigger classes */
div.category td.controls, div.recommended td.controls {width: 125px;}
a.addTrigger, a.removeTrigger, a.detailTrigger, a.buyTrigger, a.footnoteTrigger, a.serviceFootnoteTrigger, a.topSeller
{
  display: block;
  float: left;
  height: 16px;
  margin-left: 9px;
  padding: 0;
  text-indent: -9999px;
  width: 16px;
}

a.addTrigger
{
  background: url(/images/icons/add.png) center center no-repeat;
}

a.removeTrigger
{
  background: url(/images/icons/delete.png) center center no-repeat;
}

a.detailTrigger
{
  background: url(/images/icons/information.png) center center no-repeat;
}

a.buyTrigger
{
  background: url(/images/icons/cart_add.png) center center no-repeat;
}

a.topSeller
{
  background: url(/images/icons/top_seller.png) center center no-repeat;
}

a.footnoteTrigger,
a.serviceFootnoteTrigger
{
  background: url(/images/icons/error.png) center center no-repeat;
}

a.buy_link
{
}

.popover_content
{
  display: none;
}

//#viewConfig {background-color: #d31144;border: none;color: #FFFFFF;display: block;float: right;text-align: center;text-decoration: none;text-transform: uppercase;padding: 4px 13px;width: 126px;margin: 0 0 10px 0;}
//#editConfig {background-color: #d31144;border: none;color: #FFFFFF;display: block;float: right;text-align: center;text-decoration: none;text-transform: uppercase;margin-top: 15px;padding: 4px 13px;width: 126px;margin: 0 0 10px 0;}

div#filterlabel {clear: both;color: #595a5c;display: block;font-size: 9px;font-weight: normal;line-height: 1em;text-decoration: none;text-transform: uppercase;}

div.accessory_name
{
  font-size: 15px;
  font-weight: 300;
  width: 550px;
}

a.back {background: url(/images/arrow_left.png) bottom left no-repeat;color: #000;display: block;float: right;font-size: 11px;line-height: 1em;height: 11px;padding: 0 0 0 10px;text-decoration: none;text-transform: uppercase;width: 46px;}

div.footnoteIndex {float: left;}
div.description {border-bottom: 1px solid #ccc;padding-bottom: 10px;margin-bottom: 10px;}
div.footnotes {font-size: 9px;width: 550px;}
div.footnoteFlag {font-size: 9px;margin-top: 20px;}

#iconkey
{
  text-align: center;
}

ul.series {list-style: none; display: block;}

ul.series {
  list-style:none;
  margin:0;
  padding:0px;
  position: relative;
  overflow: hidden;
}

ul.series {
  list-style:none;
  margin:0;
  position: relative;
  overflow: hidden;
  padding:0px;
}

ul.series li{
  margin:0px;
  overflow: hidden;
  padding:0px;
  width: 309px;
  float: left;
}
ul.series li a{
  background-color: #c3c5c6;
  color:#000;
  display:block;
  font-weight: bold;
  margin:0px;
  padding:2px;
  text-decoration:none;
}
ul.series li a:hover{
  background-color: #c3c5c6;
}
ul.series li ul{

  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
ul.series li ul li{
  /*border-bottom: 1px solid #CCC;*/
  clear:left;
}

ul.series li ul li.last{
  border-bottom: none;
}

ul.series li ul li a{
  background:none;
  font-weight: normal;
  text-decoration:none;
  width: 309px;
  cursor: pointer;
}
ul.series li ul li a:hover{
  background-color:#E9E9E9;
}
ul.series li ul li ul{
  clear:left;
  position: relative;
  overflow: hidden;
}

#toggle_compatibility
{
  color: #0e5da1 !important;
  font-weight: bold;
  text-decoration: none;
}

#compatibility h2
{
  display: block;
  background-color:#E9E9E9;
  padding: 0 5px;
  margin: 0;
}

#compatibility h3
{
  margin: 0;
}

#compatibility div.systemtype
{
  padding: 5px;
}

#recommendedservices h4
{
  font-size: 16px;
  font-weight: bold;
}

#servicesearch
{
  background-color: #fff;
  border: 1px solid #ddd;
  overflow: hidden;
  padding: 10px;
  width: 628px;

  #fieldset {border: none;}
  #legend
  {
	background: url(/images/underline.png) bottom right no-repeat;
	border: none;
	color: #ec2527;
	font-size: 1.2em;
	font-weight: bold;
	padding-bottom: 3px;
	text-transform: uppercase;
  }

  #label {width: 200px;}

  #h4 {margin: 0;}
}

#servicesearchresults a
{
  color: #000;
  display: block;
}

h3.servicesearch
{
  background: url(/images/underline.png) bottom right no-repeat;
  border: none;
  color: #ec2527;
  display: block;
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 14px;
  padding-bottom: 6px;
  text-transform: uppercase;
  width: 200px;
}

#servicesearchform
{
  #machine_type
  {
  }
}

/* Tooltip */
.tooltip {
  display:none;
  margin: -26px 0 0 -5px;
  background: #FFF;
  border: 1px solid #AAA;
  font-size:12px;
  width:370px;
  padding:10px;
  color:#333;
  text-align: left;
  z-index: 10005;
  -webkit-box-shadow:rgba(0,0,0,0.3) -1px 1px 3px;
  -moz-box-shadow:rgba(0,0,0,0.3) -1px 1px 3px;
  box-shadow:rgba(0,0,0,0.3) 1px -1px 3px;
}

.tooltip.wide
{
  width:450px;
}

.tooltip .arrow
{
  background: url(/images/tooltip_arrow.png);
  height: 16px;
  width: 10px;
  float: left;
  margin-left: -19px;
}

.tooltip .rightarrow
{
  background: url(/images/tooltip_arrow_right.png);
  height: 16px;
  width: 10px;
  float: right;
  margin-right: -19px;
}

.tooltip.error {
  background: url(/images/bg_tooltip_error.png);
  border: 1px solid #AAA;
  color:#FFF;
}

.tooltip.error .arrow
{
  background: url(/images/tooltip_arrow_error.png);
}

.spinner {
  margin: 0 auto;
  width: 60px;
  height: 60px;
  text-align: center;
  font-size: 10px;
}

.spinner > div {
  background-color: @lenovo-red;
  height: 100%;
  width: 7px;
  display: inline-block;

  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes stretchdelay {
  0%, 40%, 100% {
	transform: scaleY(0.4);
	-webkit-transform: scaleY(0.4);
  }  20% {
	   transform: scaleY(1.0);
	   -webkit-transform: scaleY(1.0);
	 }
}
